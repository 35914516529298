import clsx from 'clsx'
import { useRouter } from 'next/router'
import { forwardRef, useMemo, memo } from 'react'
import { HorizontalScroll } from '../../components/horizontal-scroll/horizontal-scroll'
import { ListingCardPreview } from '../listing-card/listing-card-preview'
import { ListingCardSkeleton } from '../listing-card/listing-card-skeleton'
import { getEventCategoryPageSignifier } from '../tagging/get-event-category-page-signifier'
import { useTrackImpression } from '../tagging/use-track-impression'
import type { SimilarOrViewed_ListingFragment } from './__generated__/similar-properties.gql'
import themeStyles from '@brand/styles/themes/listing-card-preview-group.module.css'
import type { ViewedListingsInfo_ViewedListingReferenceFragment } from '../recently-viewed/__generated__/recently-viewed.gql'
import styles from './listing-card-preview-group.module.css'
import type { TaggingPageType } from '../tagging/tagging.const'
import type { FilterMatchResults_Fragment } from '../search/search-results/__generated__/search-results.gql'
import type { AdSlotProps as AditudeAdSlotProps } from '../ads/aditude/ad-slot'
import { AdSlot as AditudeAdSlot } from '../ads/aditude/ad-slot'
import { ListingCardPreviewRedesign } from '../listing-card/listing-card-preview-redesign'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { greatCircleDistance } from '../listing-card/listing-card.utils'

export type HostLatLng = {
  lat: number | null | undefined
  lng: number | null | undefined
}
export interface ListingCardPreviewGroupProps {
  /** Show an ad as part of the listing card preview group. */
  ad?: AditudeAdSlotProps | null
  className?: string
  isPostLead?: boolean
  /**
   * Display as a horizontal scrolling list
   * - Used for the listings group section on pdp, rent trends, lead form thank you modal.
   * - If this is not set, the list will be displayed as a vertical list
   *   (mobile) or a grid (desktop)
   */
  isHorizontal: boolean
  nearbyListingIds?: string[]
  noScrollbar?: boolean
  openInNewTab?: boolean
  fetching?: boolean
  listings?: SimilarOrViewed_ListingFragment[] | null
  viewedListings?: ViewedListingsInfo_ViewedListingReferenceFragment[] | null
  showTourCta?: boolean
  oneClickLeadActive?: boolean
  isRecentlyViewed?: boolean
  dataTagSectionPrefix?: string
  page?: TaggingPageType
  filterMatchResults?: Array<FilterMatchResults_Fragment | null>
  // Element to insert at the end of the list
  PostResultsElement?: React.FC
  hostLatLng?: HostLatLng
  hideMilesFrom?: boolean
}

const AdSlotComponent = ({
  ad,
  className,
}: {
  ad?: AditudeAdSlotProps | null
  className?: string
}) => {
  if (!ad) return null
  return (
    <li className={clsx(styles.listingCardPreviewGroupAd, className)}>
      <AditudeAdSlot {...ad} />
    </li>
  )
}

export const ListingCardPreviewGroup = forwardRef<
  HTMLUListElement,
  ListingCardPreviewGroupProps
>(function ListingCardPreviewGroupWithForwardedRef(
  props,
  horizontalListElementRef
) {
  const {
    ad,
    className,
    isPostLead = false,
    fetching,
    isHorizontal,
    listings,
    noScrollbar = false,
    viewedListings,
    openInNewTab,
    oneClickLeadActive = false,
    showTourCta,
    isRecentlyViewed = false,
    dataTagSectionPrefix = '',
    filterMatchResults,
    PostResultsElement,
    hideMilesFrom,
  } = props
  const miniListingCardPreviewEnabled =
    useFeatureVariable<number>(['mini_listing_card_redesign', 'version'], 0) > 0
  const MemoizedAd = memo(AdSlotComponent)

  const formattedListings = isRecentlyViewed
    ? viewedListings?.map((listing) => listing.listing)
    : listings
  const formattedContactedAt = isRecentlyViewed
    ? (viewedListings?.map((listing) => listing.contactedAt) as string[])
    : []

  const classNameLi = clsx({
    [styles.listingCardPreviewGroupLiHorizontal]: isHorizontal,
    [styles.listingCardPreviewGroupLiVertical]: !isHorizontal,
    [themeStyles.listingCardPreviewTheme]: true,
  })

  const { pathname } = useRouter()

  const dataTagSection = `${dataTagSectionPrefix}${getEventCategoryPageSignifier(
    pathname
  )}${isPostLead ? 'lead' : ''}`

  const listingIds = useMemo(
    () => formattedListings?.map((listing) => listing.id),
    [formattedListings]
  )

  const listingsWithDistanceFromHost = useMemo(() => {
    return formattedListings?.map((listing) => {
      let distanceFromHost

      if (
        props.hostLatLng?.lng &&
        props.hostLatLng?.lat &&
        listing.location?.lng &&
        listing.location?.lat
      ) {
        distanceFromHost = greatCircleDistance([
          props.hostLatLng?.lng,
          props.hostLatLng?.lat,
          listing.location?.lng,
          listing.location?.lat,
        ])
      }

      return {
        distanceFromHost,
        ...listing,
      }
    })
  }, [formattedListings, props.hostLatLng])

  const impressionRef = useTrackImpression(
    dataTagSection,
    {
      listingIds,
      itemName: dataTagSectionPrefix,
    },
    props.page && { page: props.page }
  )

  const ListingCardPreviewListItems = (
    <>
      {fetching
        ? [1, 2, 3, 4, 5, 6].map((_, i) => (
            <li
              className={
                isHorizontal
                  ? styles.listingCardPreviewGroupLiHorizontal
                  : styles.listingCardPreviewGroupSkeleton
              }
              key={i}
            >
              <ListingCardSkeleton />
            </li>
          ))
        : (listingsWithDistanceFromHost || []).map((listing, i) => {
            const contactedOn = formattedContactedAt[i]

            if (!listing) {
              return null
            }

            // - Ad is given order-2 for mobile, and order-last for desktop.
            // - For the first card, use order 1 so it will appear before the ad.
            // - For the other cards, use order-2 so they will appear after the ad on mobile sizes.
            const order =
              i === 0
                ? styles.listingCardPreviewGroupFirstLi
                : styles.listingCardPreviewGroupBelowAdLi

            const filterMatchResult =
              filterMatchResults?.find(
                (obj) => obj?.listingId === listing.id
              ) ?? null

            return (
              <li
                key={listing.id}
                className={clsx(classNameLi, ad && order)}
                data-tid="listing-card-preview"
              >
                {miniListingCardPreviewEnabled ? (
                  <ListingCardPreviewRedesign
                    colorScheme="light"
                    fontSize="small"
                    showTourCta={showTourCta}
                    openInNewTab={openInNewTab}
                    oneClickLeadActive={oneClickLeadActive}
                    contactedOn={contactedOn}
                    filterMatchResult={filterMatchResult}
                    hideMilesFrom={hideMilesFrom}
                    {...listing}
                  />
                ) : (
                  <ListingCardPreview
                    colorScheme="light"
                    fontSize="small"
                    showRating
                    showSquareFeet
                    showUnitsAvailable
                    showTourCta={showTourCta}
                    openInNewTab={openInNewTab}
                    oneClickLeadActive={oneClickLeadActive}
                    contactedOn={contactedOn}
                    filterMatchResult={filterMatchResult}
                    {...listing}
                  />
                )}
              </li>
            )
          })}

      {!fetching && <MemoizedAd ad={ad} className={classNameLi} />}
    </>
  )

  return (
    <div
      data-tag_section={dataTagSection}
      // Only use impressionRef after fetch complete to ensure listingIds are present
      ref={fetching ? null : impressionRef}
      className={clsx(styles.container, className)}
    >
      {isHorizontal ? (
        <HorizontalScroll
          data-tid="listing-card-preview-list"
          scrollAmount={260}
          ref={horizontalListElementRef}
          noScrollbar={noScrollbar}
        >
          {ListingCardPreviewListItems}
          {PostResultsElement && (
            <li
              key="listing-card-preview-post-result"
              className={clsx(classNameLi)}
              data-tid="listing-card-preview-post-result"
            >
              <PostResultsElement />
            </li>
          )}
        </HorizontalScroll>
      ) : (
        <ul
          className={styles.listingCardPreviewGroupUlVertical}
          data-tag_section={dataTagSection}
          data-tid="listing-card-preview-list"
        >
          {ListingCardPreviewListItems}
          {PostResultsElement && (
            <li
              key="listing-card-preview-post-result"
              className={clsx(classNameLi)}
              data-tid="listing-card-preview-post-result"
            >
              <PostResultsElement />
            </li>
          )}
        </ul>
      )}
    </div>
  )
})
