'use client'

/* eslint-disable react/jsx-no-target-blank */
import {
  noScheduleCapableCTACopy,
  shouldUseEmailLeadFormOnNoScheduleCTA,
} from '@brand/slots/detail/similar-properties-cta.const'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { CloudImage } from '../../components/cloud-image/cloud-image'
import { CTAButtons } from '../cta-buttons/cta-buttons'
import { SCHEDULE_TOUR } from '../cta-buttons/cta-buttons.const'
import { getIsScheduleTourEnabled } from '../tour-wizard/get-is-schedule-tour-enabled'
import { getListingPriceText } from '../listing/get-listing-price-text'
import type { ListingCardPreview_ListingFragment } from './__generated__/listing-card-preview.gql'
import styles from './listing-card-preview-redesign.module.css'
import type { CtaButtons_ListingFragment } from '../cta-buttons/__generated__/cta-buttons.gql'
import { useAtom } from 'jotai'
import { useHideRequestTour } from '../detail/use-hide-request-tour'
import { oneClickCookieAtom } from '../one-click-lead/one-click-lead-cookie.store'
import { ListingCardPreviewCarousel } from './listing-card-preview-carousel'
import type { LightDarkVariant } from '../../types'
import { ListingAvailabilityStatus } from '../../__generated__/api-types'
import { SEND_EMAIL_CTA_LABEL } from '@brand/features/cta-buttons/cta-buttons.const'
import type { FilterMatchResults_Fragment } from '../search/search-results/__generated__/search-results.gql'
import { getBedsText } from '../search/utils/get-listing-card-details'
import { StaticStreetView } from '../../components/static-map/static-map'
import { DiscountBadge } from './discount-badge'

type ListingCardPreviewRedesignOwnProps = {
  availabilityStatus: ListingAvailabilityStatus
  className?: string
  colorScheme: LightDarkVariant
  fontSize: 'small' | 'medium'
  isTodaysPick?: boolean
  showTourCta?: boolean
  openInNewTab?: boolean
  oneClickLeadActive?: boolean
  'data-tag_item'?: string
  contactedOn?: string
  withCarousel?: boolean
  isMobile?: boolean
  filterMatchResult?: FilterMatchResults_Fragment | null
  hideMilesFrom?: boolean
  distanceFromHost?: number
  isThankYouModal?: boolean
}

type ListingFragment = Partial<ListingCardPreview_ListingFragment>

type ListingCardPreviewRedesignProps = ListingCardPreviewRedesignOwnProps &
  ListingFragment

const cardStyles = {
  dark: styles.cardDark,
  light: styles.cardLight,
}

const infoCardColors = {
  dark: styles.infoCardDark,
  light: styles.infoCardLight,
}

const infoCardText = {
  small: styles.cardTextSmall,
  medium: styles.cardTextMedium,
}

const detailTextStyles = {
  dark: styles.detailTextDark,
  light: styles.detailTextLight,
}

export function getListingCardPreviewCTALabel(
  isScheduleTourEnabled: boolean,
  contactedOn: boolean,
  hideRequestTour: boolean,
  scheduleTourCtaText = SCHEDULE_TOUR
): string {
  if (contactedOn) {
    return 'Check Unit Availability'
  }

  if (isScheduleTourEnabled) {
    return scheduleTourCtaText
  }

  if (hideRequestTour) {
    return SEND_EMAIL_CTA_LABEL
  }

  return noScheduleCapableCTACopy
}

export function ListingCardButton({
  label,
  isScheduleTourEnabled,
  showEmailLeadFormCTA,
  contactedOn,
  openInNewTab,
  oneClickLeadActive,
}: {
  label: string
  isScheduleTourEnabled: boolean
  showEmailLeadFormCTA: boolean
  contactedOn: boolean
  openInNewTab: boolean
  oneClickLeadActive: boolean
}) {
  if (contactedOn) {
    return (
      <CTAButtons.CheckUnitAvailabilityCTA
        variant="primary"
        fluid
        size="lg"
        openInNewTab={openInNewTab}
      />
    )
  }

  if (!isScheduleTourEnabled && showEmailLeadFormCTA && !oneClickLeadActive) {
    return (
      <CTAButtons.EmailCTA
        icon={null}
        variant="primary"
        fluid
        size="lg"
        label={!oneClickLeadActive ? label : ''}
      />
    )
  }

  return (
    <>
      <CTAButtons.TourCTA
        icon={null}
        variant="primary"
        fluid
        size="lg"
        label={!oneClickLeadActive ? label : ''}
      />
    </>
  )
}

export function ListingCardPreviewRedesign({
  'data-tag_item': dataTagItem,
  className,
  colorScheme = 'dark',
  fontSize = 'medium',
  isTodaysPick = false,
  showTourCta,
  openInNewTab = false,
  oneClickLeadActive = false,
  contactedOn,
  withCarousel,
  isMobile,
  filterMatchResult,
  hideMilesFrom,
  distanceFromHost,
  isThankYouModal,
  ...listing
}: ListingCardPreviewRedesignProps) {
  const photos = listing.optimizedPhotos?.length
    ? listing.optimizedPhotos
    : listing.photos
  const firstPhoto = photos?.[0]
  const [oneClickData] = useAtom(oneClickCookieAtom)
  const hideRequestTour = useHideRequestTour(
    listing.availabilityStatus,
    listing.tourProviderDetails,
    Boolean(listing.requestTourOptOut)
  )

  const scheduleTourCtaText = useFeatureVariable<string>(
    ['tour_headline_copy', 'schedule_tour_cta_text'],
    SCHEDULE_TOUR
  )

  if (!listing.name) {
    return null
  }

  const isScheduleTourEnabled = getIsScheduleTourEnabled(
    listing.tourProviderDetails
  )

  const isAvailabilityUnknown =
    listing.availabilityStatus === ListingAvailabilityStatus.Unknown

  const label = getListingCardPreviewCTALabel(
    isScheduleTourEnabled,
    Boolean(contactedOn),
    hideRequestTour,
    scheduleTourCtaText
  )

  const photoAlt = `${listing.name} property`

  const priceText = getListingPriceText({
    priceRange: filterMatchResult?.prices,
    isBasicListing: listing.isBasic,
    fallbackPriceText: listing.priceText,
    availabilityStatus: listing.availabilityStatus,
    listingPriceRange: listing?.priceRange,
    withRange: true,
  })

  const bedsText = getBedsText({
    bedRange: filterMatchResult?.beds,
    listingBedsText: listing.bedText,
  })
  const placeAtBottom =
    (listing?.deals && listing.deals[0]) || listing.hasPriceDrops

  return (
    <div
      className={clsx(
        cardStyles[colorScheme],
        isTodaysPick && styles.todaysPick,
        className
      )}
    >
      {listing.urlPathname && (
        <a
          data-tid="pdp-link"
          href={listing.urlPathname}
          className={styles.link}
          data-tag_item={dataTagItem || listing.id}
          target={openInNewTab ? '_blank' : '_self'}
          rel={openInNewTab ? 'noreferrer' : undefined}
          aria-label={listing.name}
        />
      )}

      <div className={styles.photoWrapper}>
        {contactedOn && (
          <span
            className={clsx(
              styles.contactedOn,
              placeAtBottom && styles.bottomPosition
            )}
          >
            Contacted on {format(new Date(contactedOn), 'M/d')}
          </span>
        )}
        <DiscountBadge
          listing={listing}
          isListingCardPreviewRedesign
          isThankYouModal={isThankYouModal}
        />
        {photos?.length && photos.length > 1 && withCarousel ? (
          <ListingCardPreviewCarousel
            listingId={listing.id || ''}
            isUnpaid={listing.isUnpaid}
            photos={photos}
            isMobile={isMobile}
            name={listing.name}
            loop
            drag={false}
            urlPathname={listing.urlPathname}
          />
        ) : firstPhoto?.id ? (
          <CloudImage
            width={270}
            height={180}
            className={styles.photo}
            publicId={firstPhoto?.id ?? ''}
            size="md"
            sizeDesktop="lg"
            isUnpaid={listing.isUnpaid}
            alt={photoAlt}
            errorAlt={photoAlt}
          />
        ) : (
          <StaticStreetView
            className={styles.photo}
            lat={listing.location?.lat}
            lng={listing.location?.lng}
            name={listing?.name}
            fallbackImageSize={'lg'}
            shouldCheckMeta
          />
        )}
      </div>
      <div
        className={clsx(
          [infoCardColors[colorScheme]],
          [infoCardText[fontSize]],
          (hideMilesFrom || !distanceFromHost) &&
            showTourCta &&
            styles.reducedInfoSectionHeight
        )}
      >
        <div>
          <div className={styles.infoCardFirstLine}>
            <p className={styles.bolded}>{priceText}</p>
          </div>
          <p className={styles.bolded}>{bedsText}</p>
          <p className={detailTextStyles[colorScheme]}>{listing.name}</p>
          <p className={detailTextStyles[colorScheme]}>
            {listing.location?.city}, {listing.location?.stateAbbr}
          </p>
          {!hideMilesFrom && distanceFromHost && (
            <p className={detailTextStyles[colorScheme]}>
              {Math.round(distanceFromHost * 10) / 10} miles away
            </p>
          )}
        </div>
        {showTourCta && !listing.isBasic && !isAvailabilityUnknown && (
          <div onClick={contactedOn ? undefined : (e) => e.preventDefault()}>
            <CTAButtons
              className={styles.ctaButtons}
              listing={listing as CtaButtons_ListingFragment}
              oneClickLeadDetails={oneClickData}
              oneClickLeadActive={oneClickLeadActive}
            >
              <ListingCardButton
                label={label}
                isScheduleTourEnabled={
                  isScheduleTourEnabled && !hideRequestTour
                }
                showEmailLeadFormCTA={
                  shouldUseEmailLeadFormOnNoScheduleCTA || hideRequestTour
                }
                contactedOn={Boolean(contactedOn)}
                openInNewTab={openInNewTab}
                oneClickLeadActive={oneClickLeadActive}
              />
            </CTAButtons>
          </div>
        )}
      </div>
    </div>
  )
}
